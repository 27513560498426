.page{
    margin:1%;
}

.section{
    margin-top: 0.5%;
}

.button1{
    display:inline-block;
    padding:0.35em 1.2em;
    border:0.1em solid #FFFFFF;
    margin:0 0.3em 0.3em 0;
    border-radius:0.12em;
    box-sizing: border-box;
    text-decoration:none;
    font-weight:300;
    color:black;
    text-align:center;
    transition: all 0.2s;
    color:#000000;
}

.button1:hover{
    color:#000000;
    background-color:#FFFFFF;
    cursor: pointer;
}

.text {
    color: #FFFFFF
}
