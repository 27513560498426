.col1{
    float: left;
    width: 25%;
    padding: 2%;
}

*{
    font-family:'Roboto',sans-serif;
}

.title{
    padding: 1%;
    text-align:center;
}

.row {
    width: 100%;
}

.button1{
    display:inline-block;
    padding:0.35em 1.2em;
    border:0.1em solid #0FFFFF;
    margin:0 0.3em 0.3em 0;
    border-radius:0.12em;
    box-sizing: border-box;
    text-decoration:none;
    font-weight:300;
    color:black;
    text-align:center;
    transition: all 0.2s;
}

.button1:hover{
    color:#000000;
    background-color:#FFFFFF;
    cursor: pointer;
}
