.root {
    height: 100vh;
    width: 100vw;
}

.logo { 
    height: 100px;
    width: 350px;
}

.appBar {
    padding-left: 4vw;
    padding-right: 4vw;
    height: 10vh;
    width: 100vw;
}

.text {
    color: #FFFFFF
}

.link {
    text-decoration: none;
}

button {
    display:inline-block;
    padding:0.7em 2em;
    border:0.1em solid #FFFFFF;
    margin:0 0.3em 0.3em 0;
    border-radius:0.12em;
    box-sizing: border-box;
    text-decoration:none;
    font-weight:300;
    color:black;
    text-align:center;
    transition: all 0.2s;
    color:#000000;
}

button:hover{
    color:#000000;
    background-color:#FFFFFF;
    cursor: pointer;
}

.fileInput {
    display:inline-block;
    font-size: 1em;
    color: white;
}

.start{
    display:inline-block;
    padding:1.2em 3em;
    border:0.1em solid #5B9483;
    background-color:#5B9483;
}
